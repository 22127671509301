@import './../../static/styles/variables';

.community-tag {
    display: flex;
    justify-content: center;
    align-content: center;
    background: $white;
    border: 1px solid $divider;
    color: $brand;
    border-radius: 15px;
    font-size: small;
    margin-left: auto;
    margin-right: 10px;
    padding: 5px 10px;
    width: fit-content;
}