@import './../../static/styles/variables';

.search-input {
  width: 100%;
  position: relative;
  overflow-y: visible;
  .input {
    font-size: 16px;
    border: 1px solid $divider;
    width: 100%;
    height: 40px;
    border-radius: 8px;
    outline: none;
    padding: 10px 50px;
  }
  .focused-input {
    box-shadow: 0px 0px 5px $divider;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .search-icon {
    width: 20px;
    position: absolute;
    left: 15px;
    top: 10px;
    fill: $icon;
  }
}
.results-overlay {
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: $white;
  height: 400px;
  box-shadow: 0px 1px 5px $divider;
}

.search-input::-webkit-search-decoration:hover,
.search-input::-webkit-search-cancel-button:hover {
  cursor: pointer;
}


