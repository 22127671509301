@import '../../static/styles/variables.scss';

.outer-outline {
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 5px;
  width: 100%;
  min-height: 290px;
  margin: auto;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    border-color: rgb(218, 218, 218);
  }
  .header {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    .klatch-name {
      flex: 1;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      color: white;
      margin-left: 10px;
    }
    .menu-icon {
      height: 16px;
      width: 16px;
      margin: 4px;
      fill: #B9B9B9;
      cursor: pointer;
    }
  }
  .spots-container {
    position: relative;
    height: 180px;
    width: 180px;
    margin: 5px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    .circle-outline {
      border: 1px solid $divider;
      border-radius: 50%;
      height: 75%;
      width: 75%;
      margin: auto;
    }
    .klatch-participant-container {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .footer {
    margin: 0 8px 20px 8px;
    height: 40px;
  }
  .tags {
    margin: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    .tag {
      margin: 0 5px 5px 0;
      border-radius: 8px;
      font-weight: 600;
      height: 24px;
      padding: 3px 6px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: $background;
      color: $body;
      font-size: 12px;
    }
  }
}
