@import "../../static/styles/variables";

.login-form-container {
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 200px;
  justify-content: center;
  .form {
    width: 400px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
