@import 'static/styles/variables';

.body-text {
  color: $body;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.black-button {
  display: flex;
  color: white;
  padding: 7px 15px;
  width: fit-content;
  min-width: 80px;
  height: fit-content;
  font-weight: 600;
  background: black;
  border-radius: 20px;
  align-items: center;
  user-select: none;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #00000000;
  &:hover {
      border: 1px solid white;
  }
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    color: black;
    width: 450px;
    height: fit-content;
    min-height: 400px;
    padding: 20px 40px;
    outline: none;
    border-radius: 5px;
    .close-button {
      position: absolute;
      width: 25px;
      height: 25px;
      top: 20px;
      right: 20px;
      cursor: pointer;
      path {
        fill: #b9b9b9;
      }
    }
    .panel-heading {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .section-heading {
      margin: 10px auto 10px 10px;
      font-weight: 500;
    }
    .input-field {
      margin: 0 10px 10px 10px;
      outline: none;
      border: 1px solid black;
      padding: 10px;
      font-family: Arial, Helvetica, sans-serif;
    }
    .modal-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      .row-half {
        width: 50%;
      }
    }
    .modal-controls {
      display: flex;
      margin-top: 40px;
      text-align: center;
      .send-button {
        margin-right: 20px;
      }
      .disabled {
        pointer-events: none;
        background: #C4C4C4 !important;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
      .cancel-button {
        margin-left: auto;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
