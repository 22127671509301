@import "../../static/styles/variables";

.header-contents {
  height: $header-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 20px;
  .search-bar {
    flex: 1;
    max-width: 600px;
    height: 35px;
    margin-left: 24px;
    margin-right: 24px;
  }
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    padding: 0 10px;
  }
}
