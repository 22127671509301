@import './../../../static/styles/variables';

.search-results {
  display: flex;
  flex-direction: column;
  height: 100%;
  .result-row {
    animation-name: fadeIn;
    border-bottom: 1px solid $background;
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: $background;
    }
    .label {
      background: $white;
      border: 1px solid $divider;
      color: $brand;
      border-radius: 15px;
      font-size: x-small;
      margin-left: auto;
      margin-right: 10px;
      padding: 5px;
      width: fit-content;
    }
  }
  .helper-text {
    margin: auto;
    color: $body;
  }
}
