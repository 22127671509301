$tomato-red: #f04039;
$brand : #EB6C25;
$brandHover : #f57a36;
$subduedHover : #F6F7FA;
$heading : #121212;
$body : #5E6D84;
$icon : #A4A9B3;
$divider : #d3d4db;
$background : #F6F7FA;
$white : #FFFFFF;

$header-height: 64px;
