.klatch-participant {
  margin: auto;
  margin-top: 5px;
  width: 40px;
  height: 40px;
  background: white;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  overflow: visible;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  position: relative;
  .user-placeholder {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
